import { MediaViewModel } from "../TypeGen/media-view-model";

interface Props {
  allMedia: MediaViewModel[];
  inspectionId?: string;
  mediaId?: string;
}

export default function ImageNav({ allMedia, inspectionId, mediaId }: Props) {
  return (
    <nav>
      <div className="flex space-x-2 mb-5">
        {allMedia.map((m) => {
          return (
            <a key={m.Id} href={`Inspection/${inspectionId}/Media/${m.Id}`}>
              <img
                src={m.OriginalUrl}
                alt=""
                className={`w-[100px] h-[100px] object-cover ${
                  String(m.Id) !== mediaId ? "opacity-60 hover:opacity-95" : ""
                }`}
              />
            </a>
          );
        })}
      </div>
    </nav>
  );
}
