import { Badge } from "flowbite-react";
import { InspectionStatus } from "../TypeGen/inspection-status";

interface Props {
  status: InspectionStatus;
}

export default function StatusChip({ status }: Props) {
  switch (status) {
    case InspectionStatus.Completed:
      return <Badge color="success">Completed</Badge>;
    case InspectionStatus.Draft:
      return <Badge color="gray">Draft</Badge>;
    case InspectionStatus.Cancelled:
      return <Badge color="purple">Cancelled</Badge>;
    case InspectionStatus.InProgress:
      return <Badge color="warning">In Progress</Badge>;
    case InspectionStatus.Sent:
      return <Badge color="warning">Sent</Badge>;
    default:
      return null;
  }
}
