import { useEffect, useState } from "react";
import { useAPI } from "../hooks/api";
import { InspectionViewModel } from "../TypeGen/inspection-view-model";
import { useParams } from "react-router-dom";
import { MediaViewModel } from "../TypeGen/media-view-model";
import { Spinner } from "flowbite-react";
import StatusChip from "../components/StatusChip";
import InspectionDetails from "../components/InspectionDetails";
import TaskDisplay from "../components/TaskDisplay";

export default function Inspection() {
  const api = useAPI();
  const { id } = useParams<{ id: string }>();
  const [inspection, setInspection] = useState<InspectionViewModel>();
  const [media, setMedia] = useState<MediaViewModel[]>();

  useEffect(() => {
    if (!api || inspection) return;
    (async () => {
      const { data } = await api.get<InspectionViewModel>(
        `api/Inspection/Get/${id}`
      );

      setInspection(data);

      const { data: media } = await api.get<MediaViewModel[]>(
        `api/Media/Get/${data.Id}`
      );
      setMedia(media);
    })();
  }, [api, id, inspection]);

  console.log(inspection);

  /*const positions = media?.map((m) => {
    return new data.Position(m.Longitude, m.Latitude);
  });*/

  if (inspection == null) {
    return <Spinner />;
  }

  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="mb-4 flex items-center">
            <h2 className="mr-2 text-2xl font-semibold text-gray-900 dark:text-gray-100">
              Inspection
            </h2>
            <StatusChip status={inspection.Status} />
          </div>

          <div className="mb-4">
            <InspectionDetails inspection={inspection} />
          </div>

          {/* {positions && <DefaultMap positions={positions} />} */}
          {inspection?.Items.map((item) => (
            <TaskDisplay
              inspectionId={inspection.Id}
              item={item}
              media={media?.filter((m) => m.InspectionItemId === item.Id)}
              key={item.Id}
            />
          ))}
        </div>
      </main>
    </>
  );
}
