import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { MediaViewModel } from "../TypeGen/media-view-model";
import { useAPI } from "../hooks/api";
import { Spinner } from "flowbite-react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import MediaDetails from "../components/MediaDetails";
import ImageNav from "../components/ImageNav";

export default function Media() {
  const api = useAPI();
  const { inspectionId, mediaId } = useParams<{
    inspectionId: string;
    mediaId: string;
  }>();
  const [allMedia, setAllMedia] = useState<MediaViewModel[]>();
  const mediaRef = useRef<HTMLImageElement | null>(null);
  console.log(mediaRef);

  useEffect(() => {
    if (!api || allMedia) return;
    (async () => {
      const { data: allMedia } = await api.get<MediaViewModel[]>(
        `api/Media/Get/${inspectionId}`
      );
      setAllMedia(allMedia);
    })();
  }, [allMedia, api, inspectionId]);

  //   const positions = media?.map((m) => {
  //     return new data.Position(m.Longitude, m.Latitude);
  //   });

  if (allMedia == null) {
    return <Spinner />;
  }

  const currentMedia = allMedia.find((m) => m.Id === Number(mediaId));

  if (currentMedia == null) {
    return <Spinner />;
  }

  return (
    <main>
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <a
          href={`/Inspection/${inspectionId}`}
          className="inline-flex items-center font-medium text-gray-900 hover:text-gray-500 focus:text-gray-500 mb-2"
        >
          <ArrowLeftIcon className="mr-2 h-4 w-4" stroke="currentColor" />
          Back to inspection
        </a>
        <ImageNav
          allMedia={allMedia}
          inspectionId={inspectionId}
          mediaId={mediaId}
        />
        <div className="flex">
          <div className="mr-2">
            <img
              src={currentMedia?.OriginalUrl}
              alt=""
              className="max-h-[70vh]"
              ref={mediaRef}
            />
          </div>
          <div>
            <MediaDetails media={currentMedia} />
          </div>
        </div>
      </div>
    </main>
  );
}
