import { useEffect, useState } from "react";
import HeaderText from "../components/HeaderText";
import { useAPI } from "../hooks/api";
import { InspectionSearchViewModel } from "../TypeGen/inspection-search-view-model";
import { InspectionViewModel } from "../TypeGen/inspection-view-model";
import { InspectionStatus } from "../TypeGen/inspection-status";
import { Table } from "flowbite-react";

export default function Home() {
  const api = useAPI();
  const [inspections, setInspections] = useState<InspectionViewModel[]>();

  useEffect(() => {
    if (!api || inspections) return;
    (async () => {
      const { data } = await api.post<InspectionSearchViewModel>(
        "/api/Inspection/Search"
      );
      setInspections(data.Values);
    })();
  }, [api, inspections]);

  return (
    <>
      <HeaderText title="Home" />

      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <h2 className="mb-4 text-lg font-semibold text-gray-900 dark:text-gray-100">
            Inspections
          </h2>
          <Table>
            <Table.Head>
              <Table.HeadCell>Reference #</Table.HeadCell>
              <Table.HeadCell>Inspector</Table.HeadCell>
              <Table.HeadCell>Tasks</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">View</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {inspections?.map((inspection) => {
                return (
                  <Table.Row key={inspection.UniqueId}>
                    <Table.Cell>{inspection.Reference1}</Table.Cell>
                    <Table.Cell>
                      {inspection.Inspector.LastName}
                      {", "}
                      {inspection.Inspector.FirstName}
                    </Table.Cell>
                    <Table.Cell>{inspection.Items.length}</Table.Cell>
                    <Table.Cell>
                      {InspectionStatus[inspection.Status]}
                    </Table.Cell>
                    <Table.Cell>
                      <a
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        href={`/Inspection/${inspection.Id}`}
                      >
                        <p>View</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </main>
    </>
  );
}
