import { InspectionItemViewModel } from "../TypeGen/inspection-item-view-model";
import { MediaViewModel } from "../TypeGen/media-view-model";

interface Props {
  item: InspectionItemViewModel;
  media?: MediaViewModel[];
  inspectionId: number;
}
export default function TaskDisplay({ inspectionId, item, media }: Props) {
  return (
    <div key={item.Id} className="mb-4">
      <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
        {item.Description} ({media?.length} images)
      </h3>
      <div className="grid grid-cols-6 gap-4">
        {media?.map((m) => (
          <a href={`Inspection/${inspectionId}/Media/${m.Id}`} key={m.Id}>
            <img
              className="aspect-1 object-cover hover:brightness-[85%]"
              src={m.OriginalUrl}
              alt=""
            />
          </a>
        ))}
      </div>
    </div>
  );
}
