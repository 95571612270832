import { InspectionViewModel } from "../TypeGen/inspection-view-model";
import dayjs from "dayjs";

interface Props {
  inspection: InspectionViewModel;
}

export default function InspectionDetails({ inspection }: Props) {
  const createdDate = dayjs(inspection.CreatedDateTime).format(
    "MMMM D, YYYY h:mm A"
  );

  const output = {
    Created: createdDate,
    Customer: inspection.Customer.CustomerName,
    Inspector: `${inspection.Inspector.LastName}, ${inspection.Inspector.FirstName}`,
    "Reference #": inspection.Reference1,
  };

  return (
    <ul>
      {Object.entries(output).map(([k, v]) => {
        return (
          <li key={k} className="text-gray-900 dark:text-gray-100 font-medium">
            <span className="text-gray-600 ">{k}:</span> {v}
          </li>
        );
      })}
    </ul>
  );
}
