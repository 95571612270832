import { InspectionItemInput } from "../screens/Request";
import DraggableList from "./DraggableList";
import { Button, TextInput } from "flowbite-react";
import { CheckIcon, PencilSquareIcon, TrashIcon } from "../icons";
import { forwardRef, useRef, useState } from "react";
import { produce } from "immer";

type ListElementProps = {
  item: InspectionItemInput;
  setItem: (item: InspectionItemInput) => void;
  isEditing: boolean;
  setEditing: (b: boolean) => void;
  remove: () => void;
};

const ListElement = forwardRef<HTMLInputElement, ListElementProps>(
  function ListElement(
    { item, setItem, isEditing, setEditing, remove }: ListElementProps,
    ref
  ) {
    const { Description: text, RequiredPhotoCount } = item;

    const [editingText, setEditingText] = useState(text);
    const [editingRequiredPhotoCount, setEditingRequiredPhotoCount] = useState(RequiredPhotoCount);

    const stopEditing = () => {

      if (editingText === "" || editingRequiredPhotoCount === 0) {
        return;
      }

      setItem({
        ...item,
        Description: editingText,
        RequiredPhotoCount: editingRequiredPhotoCount,
      });
      setEditing(false);
    };

    const startEditing = () => {
      setEditing(true);
    };

    return (
      <li className="py-3 sm:py-4 px-2 rounded border bg-white dark:border-gray-600 dark:bg-slate-800">
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0">
            {isEditing ? (
              <TextInput
                ref={ref}
                type="text"
                placeholder="Task Description"
                value={editingText}
                onChange={(e) => setEditingText(e.target.value)}
              />
            ) : (
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                {text}
              </p>
            )}
          </div>
          <div className="flex-1 items-center text-base font-semibold text-gray-900 dark:text-white">
            {isEditing ? (
              <TextInput
                required
                min={1}
                max={20}
                placeholder="Photos Required"
                type="number"
                value={editingRequiredPhotoCount}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setEditingRequiredPhotoCount(+e.target.value)}
              />
            ) : (
              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                {editingRequiredPhotoCount} Photo{editingRequiredPhotoCount === 1 ? "" : "s"} Required
              </p>
            )}
          </div>
          <div
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                if (isEditing) stopEditing();
                if (!isEditing) startEditing();
              }
            }}
          >
            {isEditing ? <Button size="sm" onClick={stopEditing}>
              <CheckIcon className="fill-white w-6 h-6" />
            </Button> : <div className="flex space-x-2">
              <Button size="sm" onClick={startEditing}>
                <PencilSquareIcon className="fill-white w-6 h-6" />
              </Button>
              <Button size="sm" color="failure" onClick={remove}>
                <TrashIcon className="fill-white w-6 h-6" />
              </Button>
            </div>}
          </div>
        </div>
      </li>
    );
  }
);

type Props = {
  items: InspectionItemInput[];
  setItems: React.Dispatch<React.SetStateAction<InspectionItemInput[]>>;
};

export default function TasksInput({ items, setItems }: Props) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isEditing, setEditing] = useState<boolean[]>([]);
  const id = useRef(0);

  const addItem = (e: React.UIEvent) => {
    setItems([
      ...items,
      { Description: "", RequiredPhotoCount: 0, id: `${id.current++}` },
    ]);
    setEditing([...isEditing, true]);
    setTimeout(() => {
      inputRefs.current[inputRefs.current.length - 1].focus();
    }, 10);
  };

  return (
    <div className="space-y-6 bg-white dark:bg-slate-800 px-4 py-5 sm:p-6">
      <DraggableList
        itemRender={(item, i) => (
          <ListElement
            item={item}
            setItem={(item) => {
              setItems(
                produce(items, (oldItems) => {
                  oldItems[i] = item;
                })
              );
            }}
            isEditing={isEditing[i]}
            setEditing={(b) =>
              setEditing(
                produce(isEditing, (oldIsEditing) => {
                  oldIsEditing[i] = b;
                })
              )
            }
            remove={() => {
              setItems(
                produce(items, (oldItems) => {
                  oldItems.splice(i, 1);
                })
              );
              setEditing(
                produce(isEditing, (oldIsEditing) => {
                  oldIsEditing.splice(i, 1);
                })
              );
            }}
            ref={(e) => {
              if (e) inputRefs.current.push(e);
            }}
          />
        )}
        items={items}
        setItems={setItems}
      />
      <div className="grid grid-cols-6 gap-6">
        <div className="justify-self-end col-span-6">
          <Button
            type="button"
            color="light"
            onClick={addItem}
            disabled={isEditing.some((v) => v)}
          >
            Add Task
          </Button>
        </div>
      </div>
    </div>
  );
}
