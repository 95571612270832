import { createRoot } from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import Layout from "./screens/Layout";
import Home from "./screens/Home";
import Request from "./screens/Request";
import Customers from "./screens/Customers";
import Customer from "./screens/Customers/Edit";
import Contact from "./screens/Customers/Contacts/Edit";
import Inspectors from "./screens/Inspectors";
import Inspector from "./screens/Inspectors/Edit";
import Review from "./screens/Review";
import ReviewDetail from "./components/ReviewDetail";
import NotFound from "./screens/NotFound";
import Inspection from "./screens/Inspection";
import Media from "./screens/Media";

const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;
const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "Request", element: <Request /> },
        { path: "Customers", element: <Customers /> },
        { path: "Customer/:id", element: <Customer /> },
        {
          path: "Customer/:customerId/Contact/:contactId",
          element: <Contact />,
        },
        { path: "Inspection/:id", element: <Inspection /> },
        { path: "Inspection/:inspectionId/Media/:mediaId", element: <Media /> },
        { path: "Inspectors", element: <Inspectors /> },
        { path: "Inspector/:id", element: <Inspector /> },
        {
          path: "Review",
          element: <Review />,
          children: [{ path: ":id", element: <ReviewDetail /> }],
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ],
  {
    basename: baseUrl,
  }
);

root.render(
  <Auth0Provider
    domain="dev-7sbg53h4.us.auth0.com"
    clientId="7nRtYZH5KMqdPpzFt82iF6Fn2ADSDDey"
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://localhost:44432/",
    }}
  >
    <RouterProvider router={router} />
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
