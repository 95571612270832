import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { CustomerContactViewModel } from "../../../TypeGen/customer-contact-view-model";
import { useAPI } from "../../../hooks/api";

export default function Contact() {
  const api = useAPI();
  const { customerId, contactId } = useParams<{ customerId: string, contactId: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<CustomerContactViewModel>();

  useEffect(() => {
    async function fetch() {
      if (!api) return;
      if (contactId === "0") {
        setData({
          Id: 0,
          IsActive: true,
          FirstName: '',
          LastName: '',
          EmailAddress: '',
          SendInspectionReport: false,
          Links: []
        });
        return;
      };

      const { data } = await api.get<CustomerContactViewModel>(`api/Customer/GetContact/${contactId}`);
      setData(data);
    }

    fetch();
  }, [api, contactId]);

  const create = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const response = await api.post<{ Id: number }>(`api/Customer/CreateContact/${customerId}`, data);

    if (response.status === 201) {
      navigate(`/Customer/${customerId}`);
    } else {
      alert('Error creating contact!');
    }
  }

  const save = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const link = data?.Links.find(x => x.Name === 'Update');
    var response = await api.request({
      method: link?.Method,
      url: link?.Href,
      data: data
    })

    if (response.status === 200) {
      navigate(`/Customer/${customerId}`);
    } else {
      alert('Error saving contact!');
    }
  };

  const retire = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const link = data?.Links.find(x => x.Name === 'Retire');
    var response = await api.request({
      method: link?.Method,
      url: link?.Href,
    });

    if (response.status === 200) {
      navigate(`/Customer/${customerId}`);
    } else {
      alert('Error retiring contact!');
    }
  };

  return <>
    <main>
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <form onSubmit={(e) => contactId === '0' ? create(e) : save(e)}>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">Contact</h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-white">Enter contact information, we can use this email send all completed reports for this customer.</p>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="space-y-6 bg-white dark:bg-slate-800 px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-6 sm:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="first-name">
                            First name
                          </Label>
                        </div>
                        <TextInput
                          required
                          type="text"
                          name="first-name"
                          id="first-name"
                          value={data?.FirstName}
                          onChange={e => setData({ ...data!, FirstName: e.target.value })}
                          autoComplete="given-name"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="last-name">
                            Last name
                          </Label>
                        </div>
                        <TextInput
                          required
                          type="text"
                          name="last-name"
                          id="last-name"
                          value={data?.LastName}
                          onChange={e => setData({ ...data!, LastName: e.target.value })}
                          autoComplete="family-name"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="email-address">
                            Email Address
                          </Label>
                        </div>
                        <TextInput
                          required
                          type="email"
                          name="email-address"
                          id="email-address"
                          autoComplete="email"
                          value={data?.EmailAddress}
                          onChange={e => setData({ ...data!, EmailAddress: e.target.value })}
                          placeholder="someone@somewhere.com"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <Checkbox
                            id="send-inspection-report"
                            name="send-inspection-report"
                            checked={data?.SendInspectionReport}
                            onClick={e => setData({ ...data!, SendInspectionReport: !data?.SendInspectionReport })}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <Label
                            htmlFor="send-inspection-report"
                          >
                            Send Reports
                          </Label>
                          <p className="text-gray-500 dark:text-gray-400">Email full PDF report upon inspection completion.</p>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  {data?.Links.find(x => x.Name === 'Retire') &&
                    <button
                      type="button"
                      onClick={retire}
                      className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Retire
                    </button>
                  }
                  {data?.Links.find(x => x.Name === 'Update') &&
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  }
                  {contactId === '0' &&
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Create
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </>;
}
