import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

type GenericWithId = {
  id: string;
};

type Props<T> = {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  itemRender: (item: T, index: number) => React.ReactNode;
};

export default function DraggableList<T extends GenericWithId>({
  items,
  setItems,
  itemRender,
}: Props<T>) {
  if (items.length === 0) {
    return (
      <div className="bg-gray-50 dark:bg-gray-700 rounded p-2 flex items-center justify-center">
        <p className="text-sm text-gray-600 dark:text-white">No tasks added</p>
      </div>
    );
  }
  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (result.destination == null) return;

        setItems(reorder(items, result.source.index, result.destination.index));
      }}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div className="bg-gray-50 dark:bg-gray-700 rounded">
            <ul
              className="divide-gray-200 dark:divide-gray-700 pt-2 px-2 flex flex-col"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, i) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="mb-2"
                      >
                        {itemRender(item, i)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
