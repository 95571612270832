import HeaderText from "../components/HeaderText";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Button, Label, Select, TextInput } from "flowbite-react";
import TasksInput from "../components/TasksInput";
import { useEffect, useState } from "react";
import { useAPI } from "../hooks/api";
import { CreateInspectionViewModel } from "../TypeGen/create-inspection-view-model";
import { IDNameViewModel } from "../TypeGen/id-name-view-model";
import { useNavigate } from "react-router-dom";

// Match these up with typegen types later
export type InspectionItemInput = {
  Description: string;
  RequiredPhotoCount: number;

  // For UI only
  id: string;
};

type Inputs = {
  CustomerID: number;
  Reference1: string;
  InspectorID: number;
  Items: InspectionItemInput[];
};

export default function Request() {
  const api = useAPI();
  const navigate = useNavigate();
  const { register, handleSubmit, control, formState: { errors } } = useForm<Inputs>({
    defaultValues: {
      CustomerID: 0,
      Reference1: "",
      InspectorID: 0,
      Items: [],
    },
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    api?.post<{ id: number }>("api/Inspection/Create", data)
      .then(({ data }) => {
        api?.put(`api/Inspection/Send/${data.id}`)
          .then(() => {
            navigate('/');
          })
          .catch(() => {
            alert('Error sending inspection!');
          });
      })
      .catch(() => {
        alert('Error creating inspection!');
      });
  }

  const [, setTemplates] = useState<IDNameViewModel[]>([]);
  const [customers, setCustomers] = useState<IDNameViewModel[]>([]);
  const [inspectors, setInspectors] = useState<IDNameViewModel[]>([]);

  useEffect(() => {
    async function fetch() {
      if (!api) return;

      const { data } = await api.get<CreateInspectionViewModel>('api/Inspection/Create');
      setTemplates(data.Templates);
      setCustomers(data.Customers);
      setInspectors(data.Inspectors);
    }

    fetch();
  }, [api]);

  return (
    <>
      <HeaderText title="New Request" />

      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <form
            onSubmit={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          >
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Customer
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-white">
                    Select customer and the type of inspection you would like to
                    request.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white dark:bg-slate-800 px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="CustomerID">Customer</Label>
                        </div>
                        <Select
                          required
                          {...register("CustomerID", { required: true, min: 1, valueAsNumber: true })}
                          id="CustomerID"
                        >
                          <option value={0}>Select a Customer</option>
                          {customers.map((customer) => (
                            <option key={customer.Id} value={customer.Id}>
                              {customer.Name}
                            </option>
                          ))}
                        </Select>
                        {errors.CustomerID && <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                          <span className="font-medium">
                            Customer is required
                          </span>
                        </p>}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="Reference1">Contract #</Label>
                        </div>
                        <TextInput
                          type="text"
                          maxLength={128}
                          {...register("Reference1", { required: true, maxLength: 128 })}
                          id="Reference1"
                          placeholder="Loan, Project, etc."
                        />
                        {errors.Reference1 && <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                          <span className="font-medium">
                            Contract # is required
                          </span>
                        </p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200 dark:border-gray-700" />
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                      Inspector
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-white">
                      Enter inspector information, this will send them a SMS
                      message to download our app and perform the inspection.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="overflow-hidden shadow sm:rounded-md">
                    <div className="space-y-6 bg-white dark:bg-slate-800 px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <div className="mb-1 block">
                            <Label htmlFor="InspectorID">Inspector</Label>
                          </div>
                          <Select
                            required
                            {...register("InspectorID", { required: true, min: 1, valueAsNumber: true })}
                            id="InspectorID"
                          >
                            <option value={0}>Select an Inspector</option>
                            {inspectors.map((inspector) => (
                              <option key={inspector.Id} value={inspector.Id}>
                                {inspector.Name}
                              </option>
                            ))}
                          </Select>
                          {errors.InspectorID && <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                          <span className="font-medium">
                            Inspector is required
                          </span>
                        </p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200 dark:border-gray-700" />
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                      Tasks
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-white">
                      Enter required task information.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="overflow-hidden shadow sm:rounded-md">
                    <Controller
                      name="Items"
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { value: items, onChange: setItems },
                      }) => <TasksInput items={items} setItems={setItems} />}
                    />
                    {errors.Items && <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">
                        At least one task is required
                      </span>
                    </p>}
                  </div>
                  <div className="px-4 py-3 text-right sm:px-6">
                    <Button type="submit" className="inline-flex ">
                      Send Request
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}
