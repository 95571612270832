import { Link } from "react-router-dom";
import { Table } from "flowbite-react";
import { CustomerContactViewModel } from "../../../TypeGen/customer-contact-view-model";

type Props = {
  customerId: string | undefined;
  data: CustomerContactViewModel[] | undefined;
}

export default function Contacts({ customerId, data }: Props) {
  const renderTableHeader = <>
    <Table.HeadCell>
      Contact Name
    </Table.HeadCell>
    <Table.HeadCell>
      Cell Phone
    </Table.HeadCell>
    <Table.HeadCell>
      Send Reports
    </Table.HeadCell>
    <Table.HeadCell>
      Active
    </Table.HeadCell>
  </>;

  const renderTableRow = (contact: CustomerContactViewModel) => (
    <Table.Row
      key={contact.Id}
      className="bg-white dark:border-gray-700 dark:bg-gray-800"
    >
      <Table.Cell>
        <Link
          className="font-medium text-blue-600 hover:text-blue-700 dark:text-blue-500"
          to={`/Customer/${customerId}/Contact/${contact.Id}`}
        >
          {contact.FirstName} {contact.LastName}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {contact.EmailAddress}
      </Table.Cell>
      <Table.Cell>
        {contact.SendInspectionReport ? 'Yes' : 'No'}
      </Table.Cell>
      <Table.Cell>
        {contact.IsActive ? 'Active' : 'Retired'}
      </Table.Cell>
    </Table.Row>
  );

  const renderTable = () => (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full pt-3 px-2">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <Table className={`min-w-full ${data === undefined ? 'animate-pulse' : ''}`}>
              <Table.Head>
                {renderTableHeader}
              </Table.Head>
              <Table.Body className="divide-y">
                {data?.map((x) => renderTableRow(x))}
                {data?.length === 0 && <td colSpan={4} className="py-4 px-6 text-sm font-medium bg-white dark:bg-gray-800 dark:text-white">No contacts found</td>}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  if (data === undefined) return null;

  return <>
    <div className="mx-auto max-w-7xl sm:px-6">
      {renderTable()}
    </div>
  </>;
}