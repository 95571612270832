import { Dialog, Transition } from "@headlessui/react";
import { HeartIcon, PlusSmallIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ReviewDetail() {
  const [open] = useState(true);
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();

  const setOpen = (bool: boolean) => {
    navigation("/Review");
  }

  return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pointer-events-auto relative w-96 lg:w-[75vw] xl:w-[50vw]">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-full overflow-y-auto bg-white dark:bg-slate-800 p-8">
                <div className="space-y-6 pb-16">
                  <div>
                    <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                      <img
                        src={`https://source.unsplash.com/${id}/1600x900`}
                        alt=""
                        className="object-cover"
                      />
                    </div>
                    <div className="mt-4 flex items-start justify-between">
                      <div>
                        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-300">
                          IMG_4985.HEIC
                        </h2>
                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">3.9 MB</p>
                      </div>
                      <button
                        type="button"
                        className="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-slate-800 text-gray-400 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-slate-900 hover:text-gray-500 dark:hover:text-slate-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        <HeartIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-gray-300">Metadata</h3>
                    <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Uploaded</dt>
                        <dd className="text-gray-900 dark:text-gray-300">10/04/2022 14:23:22 PST</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Taken On</dt>
                        <dd className="text-gray-900 dark:text-gray-300">iPhone 14 Pro</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Coordinates</dt>
                        <dd className="text-gray-900 dark:text-gray-300">38.56191,-121.58299</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Approx. Address</dt>
                        <dd className="text-gray-900 dark:text-gray-300">405 Seaport Blvd<br />West Sacramento</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Accuracy</dt>
                        <dd className="text-gray-900 dark:text-gray-300">2 meters</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500 dark:text-gray-400">Dimensions</dt>
                        <dd className="text-gray-900 dark:text-gray-300">4032 x 3024</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-gray-300">Note</h3>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-sm text-gray-900 dark:text-gray-300">Ensuite bathroom pictured</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-gray-300">Shared with</h3>
                    <ul className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                      <li className="flex items-center justify-between py-3">
                        <div className="flex items-center">
                          <img
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80"
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                          <p className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Megan Price<br />
                            <span className="text-gray-500 dark:text-gray-400">Belwood Investments Inc.</span>
                          </p>
                        </div>
                        <button
                          type="button"
                          className="ml-6 rounded-md bg-white dark:bg-slate-800 text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Remove
                        </button>
                      </li>
                      <li className="flex items-center justify-between py-3">
                        <div className="flex items-center">
                          <img
                            src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                          <p className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Bo Belmont<br />
                            <span className="text-gray-500 dark:text-gray-400">Belwood Investments Inc.</span>
                          </p>
                        </div>
                        <button
                          type="button"
                          className="ml-6 rounded-md bg-white dark:bg-slate-800 text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Remove
                        </button>
                      </li>
                      <li className="flex items-center justify-between py-2">
                        <button
                          type="button"
                          className="group -ml-1 flex items-center rounded-md bg-white dark:bg-slate-800 p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 dark:border-slate-600 text-gray-400 dark:text-slate-700">
                            <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                          <span className="ml-4 text-sm font-medium text-indigo-600 dark:text-indigo-400 group-hover:text-indigo-500 dark:group-hover:text-indigo-300">
                            Share
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="flex">
                    <button
                      type="button"
                      className="flex-1 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Download
                    </button>
                    <button
                      type="button"
                      className="ml-3 flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
}