import dayjs from "dayjs";
import { MediaViewModel } from "../TypeGen/media-view-model";
import { DATETIME_FORMAT } from "../util/constants";

interface Props {
  media: MediaViewModel;
}

const LAT_LONG_PRECISION = 5;

function latLongToString(latitude: number, longitude: number) {
  const truncatedLat = Math.abs(latitude).toFixed(LAT_LONG_PRECISION);
  const truncatedLong = Math.abs(longitude).toFixed(LAT_LONG_PRECISION);
  return `${truncatedLat}${latitude > 0 ? "N" : "S"}, ${truncatedLong}${
    longitude > 0 ? "E" : "W"
  }`;
}

export default function MediaDetails({ media }: Props) {
  console.log(media);
  const output = {
    "Device time": dayjs(media.DeviceDateTime).format(DATETIME_FORMAT),
    "Upload time": dayjs(media.ServerDateTime).format(DATETIME_FORMAT),
    Location: latLongToString(media.Latitude, media.Longitude),
    Altitude: `${media.Altitude.toFixed(2)}m`,
    "Device Model": `${media.DeviceMake} ${media.DeviceModel}`,
    "Camera Details": `f/${Number(media.FNumber.toFixed(2))} 1/${(
      1 / media.ExposureTime
    ).toFixed(0)}s ${Number(media.FocalLength.toFixed(2))}mm`,
    Flash: !!media.Flash ? "On" : "Off",
    "Original Resolution": `${media.Height} x ${media.Width}`,
  };

  return (
    <ul>
      {Object.entries(output).map(([k, v]) => {
        return (
          <li key={k} className="text-gray-900 dark:text-gray-100 font-medium">
            <span className="text-gray-600 ">{k}:</span> {v}
          </li>
        );
      })}
    </ul>
  );
}
