import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAPI } from "../../hooks/api";
import { CustomerViewModel } from "../../TypeGen/customer-view-model";
import Contacts from "./Contacts";
import { Label, TextInput } from "flowbite-react";

export default function Customer() {
  const api = useAPI();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<CustomerViewModel>();

  useEffect(() => {
    async function fetch() {
      if (!api) return;
      if (id === "0") {
        setData({
          Id: 0,
          IsActive: true,
          CustomerName: '',
          Contacts:  [],
          Links: []
        });
        return;
      };

      const { data } = await api.get<CustomerViewModel>(`api/Customer/Get/${id}`);
      setData(data);
    }

    fetch();
  }, [api, id]);

  const create = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const response = await api.post<{ Id: number }>(`api/Customer/Create`, data);

    if (response.status === 201) {
      navigate(`/Customer/${response.data.Id}`);
    } else {
      alert('Error creating customer!');
    }
  }

  const save = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const link = data?.Links.find(x => x.Name === 'Update');
    var response = await api.request({
      method: link?.Method,
      url: link?.Href,
      data: data
    })

    if (response.status === 200) {
      navigate('/Customers');
    } else {
      alert('Error saving customer!');
    }
  };

  const retire = async (e: any) => {
    e.preventDefault();

    if (!api) return;

    const link = data?.Links.find(x => x.Name === 'Retire');
    var response = await api.request({
      method: link?.Method,
      url: link?.Href,
    });

    if (response.status === 200) {
      navigate('/Customers');
    } else {
      alert('Error retiring customer!');
    }
  };

  return <>
    <main>
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <form onSubmit={(e) => id === '0' ? create(e) : save(e)}>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">Customer</h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-white">This is the owner of the property you are inspecting. Optionally, setup contacts who can receive the completed inspection reports.</p>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="space-y-6 bg-white dark:bg-slate-800 px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-6 lg:col-span-3">
                        <div className="mb-1 block">
                          <Label htmlFor="customer-name">
                            Customer name
                          </Label>
                        </div>
                        <TextInput
                          required
                          type="text"
                          name="customer-name"
                          id="customer-name"
                          value={data?.CustomerName}
                          onChange={e => setData({ ...data!, CustomerName: e.target.value })}
                          autoComplete="given-name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex justify-end">
                  {data?.Links.find(x => x.Name === "CreateContact") && <Link
                    to={`/Customer/${id}/Contact/0`}
                    className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    + Contact
                  </Link>}
                </div>
                <Contacts customerId={id} data={data?.Contacts} />
                <div className="px-4 py-3 text-right sm:px-6">
                  {data?.Links.find(x => x.Name === 'Retire') &&
                    <button
                      type="button"
                      onClick={retire}
                      className="inline-flex justify-center py-2 px-4 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Retire
                    </button>
                  }
                  {data?.Links.find(x => x.Name === 'Update') &&
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  }
                  {id === '0' &&
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Create
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </>;
}
