type Props = {
  title: string;
  children?: React.ReactNode;
}

export default function ReviewDetail({ title, children }: Props) {
  return <header className="bg-white dark:bg-slate-800 shadow">
    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 justify-between flex">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300">{title}</h1>
      {children}
    </div>
  </header>;
}