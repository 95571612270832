import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "flowbite-react";
import HeaderText from "../../components/HeaderText";
import { useAPI } from "../../hooks/api";
import { CustomerSearchViewModel } from "../../TypeGen/customer-search-view-model";
import { CustomerViewModel } from "../../TypeGen/customer-view-model";

export default function Customers() {
  const api = useAPI();
  const [data, setData] = useState<CustomerSearchViewModel>();

  useEffect(() => {
    async function fetch() {
      if (!api) return;

      const { data } = await api.post<CustomerSearchViewModel>('api/Customer/Search');
      setData(data);
    }

    fetch();
  }, [api]);

  const renderTableHeader = <>
    <Table.HeadCell>
      Customer Name
    </Table.HeadCell>
    <Table.HeadCell>
      Active
    </Table.HeadCell>
  </>;

  const renderTableRow = (customer: CustomerViewModel) => (
    <Table.Row
      key={customer.Id}
      className="bg-white dark:border-gray-700 dark:bg-gray-800"
    >
      <Table.Cell>
        <Link
          className="font-medium text-blue-600 hover:text-blue-700 dark:text-blue-500"
          to={`/Customer/${customer.Id}`}
        >
          {customer.CustomerName}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {customer.IsActive ? 'Active' : 'Retired'}
      </Table.Cell>
    </Table.Row>
  );

  const renderTable = () => (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-6 sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <Table className={`min-w-full ${data === undefined ? 'animate-pulse' : ''}`}>
              <Table.Head>
                {renderTableHeader}
              </Table.Head>
              <Table.Body className="divide-y">
                {data?.Values.map((x) => renderTableRow(x))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  return <>
    <HeaderText title="Customers">
      {data?.Links.find(x => x.Name === "Create") && <Link
        to="/Customer/0"
        className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        + Customer
      </Link>}
    </HeaderText>
    <div className="mx-auto max-w-7xl sm:px-6">
      {renderTable()}
    </div>
  </>;
}